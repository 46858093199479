import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';



const Card = ({ title, description, description2, cta, ctaUrl, img, id, classCard, classTitle = 'text-gray-800'}) => {
    const ctaPosition = 'justify-center';
    return (<>

        <div className="w-full md:w-1/2 lg:w-1/3 p-6 flex flex-col flex-grow mb-10 lg:mb-0 mt-10 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105" id={id}>
                <div 
                    data-sal="zoom-in"
                    data-sal-delay="100"
                    data-sal-duration="700"
                    data-sal-easing="ease"
                    className={`flex flex-col flex-grow justify-start bg-white rounded-t rounded-b-none shadow-lg ${classCard}`}>

                    <div className="flex justify-center md:justify-center -mt-16 pb-8">
                        <img src={img} 
                            className="w-32 h-24"
                        />
                    </div>
                    
                    <div className="flex flex-wrap no-underline hover:no-underline">
                        <div className={`w-full font-bold text-center text-xl px-6 uppercase ${classTitle}`}>{title}</div>
                        <p className="text-gray-600 text-base md:text-lg px-6 pt-4 mb-5 text-center">
                            {description}<br/> 
                            {description2}
                        </p>
                    </div>
                    {cta ? 
                    <div className={`self-center flex items-center ${ctaPosition}`}>
                        <Link to={ctaUrl ? ctaUrl : '#'}>
                            <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">{cta}</button>
                        </Link>
                    </div>
                    : ''
                    }
                </div>
        </div>
    </>);
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    description2: PropTypes.string,
    cta: PropTypes.string,
    ctaUrl: PropTypes.string,
    id: PropTypes.string,
    img: PropTypes.string.isRequired,
    classCard: PropTypes.string,
    classTitle: PropTypes.string,
}

export default Card;
import PropTypes from 'prop-types';
import React from 'react';

const Phrase = ({ title, description, img, reverse, colorTitle = 'text-blue-700', classPhrase='bg-white'}) => {

    return (<>


        <div className={`w-full p-6 flex content-center justify-center bg-white border-t-2 border-b-2 ${classPhrase}`}>
            
            <div className={`flex flex-col w-full items-center justify-center lg:w-5/6 md:flex-row ${reverse ? 'md:flex-row-reverse' : ''} `}>

                {img &&

                    <div 
                        data-sal="slide-right"
                        data-sal-delay="100"
                        data-sal-duration="700"
                        data-sal-easing="ease"
                        className="sm:w-3/12"
                        once >
                        <img src={img}
                        className="w-32 md:w-48 "
                        />
                    </div>
                }
                

                <div 
                    data-sal="slide-left"
                    data-sal-delay="100"
                    data-sal-duration="700"
                    data-sal-easing="ease"
                    className="mt-10 md:mt-0 flex flex-col justify-center">
                    <div className={`w-full font-bold text-center text-lg md:text-2xl px-6 uppercase ${colorTitle} `}>{title}</div>
                    {description? 
                    <p className="text-gray-800 text-base px-6 pt-4 mb-5 text-center">
                        {description}
                    </p>
                    : ''}
                </div>

            </div>
        </div>
    </>);
}

Phrase.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    colorTitle: PropTypes.string,
    reverse: PropTypes.string,
    classPhrase: PropTypes.string,
    img: PropTypes.string.isRequired,
}

export default Phrase;
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';

const FeatureWithImageCta = ({ title, title2, description, description2, description3, img, reverse, ctaText, ctaUrl, 
    classCard = 'bg-white', 
    classTitle='text-gray-800',
    classDescription='text-gray-600'
}) => {
    
    
    return (<>
        <div className="bg-white container-xl md:pb-10 md:mx-auto flex flex-row justify-center md:mt-5">
            <div className={`bg-white md:mx-8 md:flex md:max-w-6xl md:shadow-lg md:rounded-lg ${classCard}`}>
                <div className={`flex flex-wrap justify-center ${reverse ? 'flex-row-reverse' : ''} `}>

                    <div className="w-full sm:w-1/2 p-6">
                        <div className="w-full lg:w-5/6 py-6 flex justify-center">
                            <img className="w-5/6" src={img} />
                        </div>
                    </div>

                    <div className="pb-10 sm:py-12 px-6 max-w-2xl md:max-w-5xl md:w-1/2">
                        <h2 className={`text-3xl font-bold text-center lg:text-left ${classTitle}`} >{title}<span className="text-blue-700">{title2 || ''}</span></h2>
                        <p className={`mt-4 text-xl text-center lg:text-left ${classDescription}`}>{description}</p>
                        <p className={`mt-4 text-xl text-center lg:text-left ${classDescription}`}>{description2}</p>
                        <p className={`mt-4 text-xl text-center lg:text-left ${classDescription}`}>{description3}</p>
                        
                        {ctaUrl? 
                        <div className='mt-5 text-center lg:text-left'>
                            <Link to={ctaUrl}>
                                <button className="mx-auto lg:mx-0 hover:underline bg-blue-600 text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">{ctaText}</button>
                            </Link>
                        </div>
                        : ''}
                    </div>


                </div>
            </div>
        </div>
    </>);
}

FeatureWithImageCta.propTypes = {
    title: PropTypes.string.isRequired,
    title2: PropTypes.string,
    description: PropTypes.string,
    description2: PropTypes.string,
    description3: PropTypes.string,
    img: PropTypes.string.isRequired,
    reverse: PropTypes.bool,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    classCard: PropTypes.string,
    classTitle: PropTypes.string,
    classDescription: PropTypes.string,

}

export default FeatureWithImageCta;
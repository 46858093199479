import React from "react";

import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';

import Layout from "../components/layout";
import Hero from '../components/Hero';
import SectionDivisor from '../components/SectionDivisor';
import ContainerSection from '../components/ContainerSection';
import HeroCta from '../components/HeroCta';
import Brands from '../components/Brands';
// import CardStats from '../components/CardStats';

import Card from '../components/Card';
// import Pasos from '../components/Pasos';

import SectionTitle from '../components/SectionTitle';
import FeatureWithImage from '../components/FeatureWithImage';
import FeatureWithImageCta from '../components/FeatureWithImageCta';
// import CardIndex from '../components/CardIndex';
import Phrase from '../components/Phrase';


import imageHero from '../images/servicios-digitales.svg';

import DesarrolloSoftware from '../images/desarrollo-software.svg';
import DesarrolloApp from '../images/desarrollo-app.svg';

import MarketingDigital from '../images/marketing-digital.svg';
import seo from '../images/seo.svg';
import sem from '../images/sem.svg';
import emailmarketing from '../images/emailmarketing.svg';
import consultoriadigital from '../images/consultoriadigital.svg';
import socialmedia from '../images/socialmedia.svg';
import crecimiento from '../images/crecimiento.svg';
import desarrolloweb from '../images/desarrolloweb.svg';
import ecommerce from '../images/ecommerce.svg';
import desarrollomedida from '../images/desarrollomedida.svg';
import Comotrabajamosdigitales from '../components/Comotrabajamosdigitales';
import notification from '../images/notification.svg';


function IndexPage() {

  const { tecnologias } = useStaticQuery(graphql`
  query {
      tecnologias: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "tecnologias"}}) {
        edges {
          node {
            id
            name
            childImageSharp{
              fluid{
                  ...GatsbyImageSharpFluid_withWebp
              }
          }
          }
        }
      }
      
  }
  `);

const tecnologiasImg = tecnologias.edges.map(img=>(img.node.childImageSharp.fluid.src));
tecnologiasImg.sort();


  return (
    <Layout>

      <Hero
        header={'¿A qué nos dedicamos?'}
        title={'SERVICIOS DIGITALES'}
        subtitle={'Desarrollamos soluciones efectivas y a la medida de cada necesidad.'}
        image={imageHero}

      />

      <SectionDivisor type={1} />


      <ContainerSection
        type={6}
      >

          <SectionTitle title={'Diferenciate de la competencia'} 
            classTitle='mt-16'
            />

          <FeatureWithImage
            title={'Desarrollo Web'}
            description={'Un sitio web no solo debe llamar la atención, además debe atraer e involucrar al usuario, transmitir, comunicar y presentar los productos y/o servicios que tu organización ofrece.'}
            description2={'Cada proyecto tiene sus objetivos, sus usuarios y sus tiempos de desarrollo. Utilizamos metodologías ágiles para acelerar los tiempos de desarrollo, trabajar de manera ordenada y alcanzar los objetivos de tu proyecto.'}
            description3={'Brindamos un servicio completo, desde la planificación del proyecto, el diseño del mismo y el posterior desarrollo. Contamos con un equipo de profesionales el cual se capacita día a día en las nuevas tecnologías.'}
            img={DesarrolloSoftware}
          />
          
          {[
          {
            title: 'Sitios Web',
            // description: 'Tu sitio web es la cara de tu empresa en internet, mejoremosla!',
            cta: 'Consultar',
            ctaUrl: '/contacto#contactForm',
            img: desarrolloweb,
          },
          {
            title: 'Tiendas Online',
            // description: 'Obtené tu tienda online y comenzá a vender en internet',
            cta: 'Consultar',
            ctaUrl: '/contacto#contactForm',
            img: ecommerce,
          },
          {
            title: 'Desarrollo a Medida',
            // description: 'Creamos productos digitales que solucionan problemas.',
            cta: 'Consultar',
            ctaUrl: '/contacto#contactForm',
            img: desarrollomedida,
          }
          ].map(card => (
            <Card
              key={card.title}
              title={card.title}
              description={card.description}
              description2={card.description2}
              id={card.id}
              img={card.img}
              // classTitle={'text-white'}
              // classCard={'purpurafeedbackFondo'}
            />
          ))
          }

      <Phrase 
        title={'Descrubamos el potencial de tu organización'}
        colorTitle={'text-pink-700'}
        img={crecimiento}
      />

        <FeatureWithImage
            title={'Desarrollo de Aplicaciones'}
            description={'El número de usuarios de dispositivos móviles crece día a día, y las necesidades de los usuarios también lo hacen. Las organizaciones que entienden la problemática se involucran en el desarrollo de aplicaciones móviles para lograr la cercanía con sus usuarios y brindar una respuesta más rápida a sus requerimientos.'}
            description2={'Diseñamos y desarrollamos tu aplicación a medida, adaptada a las necesidades de tu organización. Nos encargamos de todo el proceso, desde el desarrollo hasta la publicación en los Store\'s.'}
            description3={'Somos creativos, optimizamos costos y trabajamos contrarreloj para lograr los mejores resultados en el menor tiempo posible.'}
            img={DesarrolloApp}
            reverse
          />

    </ContainerSection>



    <Comotrabajamosdigitales />

      <ContainerSection
        type={6}
      >

          <FeatureWithImageCta
            title={'Marketing Digital'}
            description={'Cautivamos a tus clientes potenciales a través de la creación de contenido y de campañas estratégicamente segmentadas para alcanzar tus metas comerciales'}
            description2={'Trabajamos con los métodos más efectivo del momento, utilizamos las últimas herramientas para maximizar el rendimiento de tus campañas.'}
            description3={'Creamos estrategias efectivas para captar a tu público objetivo, generar visitas y convertirlas en clientes.'}
            img={MarketingDigital}
            classCard={'gradient2'}
            classTitle={'text-white'}
            classDescription={'text-gray-300'}
            />

        {[
          {
            title: 'Social Media',
            description: 'Creamos estrategias efectivas en redes sociales para ayudarte a hacer crecer tu negocio, mejorar tu presencial digital y acercarte a tus clientes potenciales.',
            cta: 'Consultar',
            ctaUrl: '/contacto#contactForm',
            img: socialmedia,
          },
          {
            title: 'Email Marketing',
            description: 'Elaboramos campañas de mailing personalizadas para cada cliente. Publicitamos tus productos y servicios con el objetivo de atraer a nuevos clientes a tu negocio.',
            cta: 'Consultar',
            ctaUrl: '/contacto#contactForm',
            img: emailmarketing,
          },
          {
            title: 'Consultoría Digital',
            description: 'Desarrollamos, gestionamos y optimizamos tu plan de marketing digital, te asesoramos en la toma de decisiones para obtener resultados, cumplir tus objetivos y hacer crecer tu negocio.',
            cta: 'Consultar',
            ctaUrl: '/contacto#contactForm',
            img: consultoriadigital,
          },
          {
            title: 'SEO',
            description: 'Analizamos tu sitio web y desarrollamos estrategias digitales para aumentar el posicionamiento orgánico de tu organización gracias a la creación de contenidos relevantes y originales.',
            cta: 'Consultar',
            ctaUrl: '/contacto#contactForm',
            img: seo,
          },
          {
            title: 'SEM',
            description: 'Creamos, optimizamos y personalizamos campañas publicitarias en motores de búsqueda como Google para lograr el máximo beneficio y alcanzar tus objetivos comerciales.',
            cta: 'Consultar',
            ctaUrl: '/contacto#contactForm',
            img: sem,
          }
          ].map(card => (
            <Card
              key={card.title}
              title={card.title}
              description={card.description}
              description2={card.description2}
              id={card.id}
              img={card.img}
            />
          ))
          }

      </ContainerSection>

      <ContainerSection
        type={6}
      >

    <Link to={'/contacto'}>
      <div className="flex justify-center items-center w-full mb-10 md:my-10 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
        <div className="m-auto">
          <div className="bg-white rounded-lg border-gray-300 border p-3 shadow-lg">
            <div className="flex flex-row">
              <div className="px-2">
                <img src={notification} className="h-12" />
                  <path d="M1299 813l-422 422q-19 19-45 19t-45-19l-294-294q-19-19-19-45t19-45l102-102q19-19 45-19t45 19l147 147 275-275q19-19 45-19t45 19l102 102q19 19 19 45t-19 45zm141 83q0-148-73-273t-198-198-273-73-273 73-198 198-73 273 73 273 198 198 273 73 273-73 198-198 73-273zm224 0q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
              </div>
              <div className="ml-2 mr-6">
                <span className="font-semibold text-gray-700">Nueva notificación</span>
                <span className="block text-gray-600">Feedback IT quiere ayudarte a crecer!</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>

      </ContainerSection>




      <Brands
        title='Tecnologías'
        logos={tecnologiasImg.map(tecnologia=>({
          classesBrand: 'h-auto lg:h-16',
          img: tecnologia
        })
        )
      }
        bgColorTailwind="bg-white"
        quantityItems={6}
        autoplay
      />
  

      <SectionDivisor
        color1={'#FFFFFF'}
        color2={'#FFFFFF'}
        type={2}
      />

      <HeroCta
        cta={'Tenés una idea?'}
        message={'Hagamosla realidad!'}
        actionText={'Enviar mensaje'}
        url={'/contacto#contactForm'}
      />


    </Layout>
  );
}

export default IndexPage;

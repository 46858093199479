import React from 'react';

import paso1soluciones from '../images/paso1soluciones.svg';
import paso2soluciones from '../images/paso2soluciones.svg';
import paso3soluciones from '../images/paso3soluciones.svg';

const Comotrabajamosdigitales = () => {


    return (
    <>
        <div className="w-full flex flex-col justify-center items-center bg-gray-100 border-2 py-8">
            
        <h1 className="text-2xl md:text-4xl text-center azuloscurofeedback uppercase font-black">Así lo hacemos:</h1>
            <div className="flex justify-center w-full mb-5 border-gray-500">
                <div className="w-2/12 border"></div>
            </div>
            
            {/* <div className="flex flex-col w-full md:w-5/6 items-center md:items-start divide-y divide-blue-200 bg-white rounded p-4 shadow-lg mb-10 md:mb-0"> */}
            <div className="flex flex-col w-full md:w-5/6 xl:w-4/6 items-center md:items-start divide-y divide-pink-200">

                <div className="flex flex-col md:flex-row w-full mb-6 md:mb-0 items-center justify-start">
                    <img className="h-32 mb-3 md:mr-10" src={paso1soluciones}></img>
                    <div className="flex flex-col items-center md:items-start mx-5 sm:mx-0">
                        <div className=" flex justify-center items-center text-xl md:text-2xl font-black purpurafeedback">RELEVAMOS</div>
                        <p className="text-gray-700 text-center md:text-left">Escuchamos tu idea, analizamos los requerimientos y objetivos a alcanzar para proponer mejoras.</p>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row w-full mb-6 md:mb-0 items-center justify-start pt-5 ">
                    <img className="h-32 mb-3 md:mr-10" src={paso2soluciones}></img>
                    <div className="flex flex-col items-center md:items-start mx-5 sm:mx-0">
                        <div className=" flex justify-center items-center text-xl md:text-2xl font-black purpurafeedback">DISEÑAMOS</div>
                        <p className="text-gray-700 text-center md:text-left">Presentamos diferentes opciones, estimamos sus tiempos, estudiamos los riesgos y factores de éxito. Confeccionamos los procedimientos y armamos el plan de acción.</p>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row w-full mb-6 md:mb-0 items-center justify-start pt-5 ">
                    <img className="h-32 mb-3 md:mr-10" src={paso3soluciones}></img>
                    <div className="flex flex-col items-center md:items-start mx-5 sm:mx-0">
                        <div className=" flex justify-center items-center text-xl md:text-2xl font-black purpurafeedback">CONSTRUIMOS</div>
                        <p className="text-gray-700 text-center md:text-left">Un equipo de especialistas llevará adelante la realización del proyecto según lo planificado, asegurando el cumplimiento de todas las metas definidas.</p>
                    </div>
                    </div>

            </div>

            
        </div>
                
    </>);
}

// Comotrabajamosdigitales.propTypes = {
   
// }

export default Comotrabajamosdigitales;